<template>
  <base-section
    id="affiliates"
    space="36"
  >
    <v-container>
      <base-section-heading title="Our Partners" />

      <v-slide-group>
        <template v-for="i in logos">
          <v-slide-item
            :key="i.name"
            class="align-self-center"
          >
            <base-img
              :src="require(`@/assets/partners/${i.img}`)"
              color="grey"
              contain
              height="64"
              width="128"
            />
          </v-slide-item>

          <!-- v-if="Object.keys(logos).length < 6" -->
          <v-responsive
            :key="`divider-${i.name}`"
            class="text-center"
            height="56"
            width="48"
          >
            <v-divider vertical />
          </v-responsive>
        </template>
      </v-slide-group>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionAffiliates',

    data: () => ({
      logos: [
        {
          name: 'BP',
          img: 'BP.png',
        },
        {
          name: 'Shell',
          img: 'Shell.png',
        },
        {
          name: 'Fugro',
          img: 'Fugro.png',
        },
        {
          name: 'Petronas',
          img: 'Petronas.png',
        },
        {
          name: 'HGIS',
          img: 'HGIS.png',
        },
        {
          name: 'RTSD',
          img: 'RTSD.png',
        },
        {
          name: 'DOSMVN',
          img: 'DOSMVN.png',
        },
        {
          name: 'BOM',
          img: 'BOM.png',
        },
        {
          name: 'Certeza',
          img: 'Certeza.png',
        },
        {
          name: 'DRDLR',
          img: 'DRDLR Logo.gif',
        },
        {
          name: 'NAMRIA',
          img: 'NAMRIA.png',
        },
        {
          name: 'PHIVOLCS',
          img: 'PHIVOLCS.png',
        },
        {
          name: 'SISC',
          img: 'SISC.png',
        },
        {
          name: 'Vicmap',
          img: 'Vicmap.png',
        },
        {
          name: 'WALCORS',
          img: 'WALCORS.png',
        },
      ],
    }),
  }
</script>
